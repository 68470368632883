.resume-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: fit-content;
  margin: 100px 0;
}

.resume-card {
  display: flex;
  height: 360px;
  width: 1000px;
  /* margin: 80px 0; */
  /* background-color: tomato; */
}

.resume-bullets {
  box-shadow: 15px 0 9px -15px #1f2235;
  height: 100%;
  width: 320px;
}

.resume-bullet-details {
  flex-grow: 1;
  width: 600px;
  overflow: hidden;
  padding: 0 0 0 80px;
}

.experience-description {
  margin: 10px 0 0 0;
  text-align: justify;
}

.resume-description-text {
  font-size: 15px;
}

.resume-details-carousal {
  position: relative;
  height: auto;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.active-section {
  display: block;
  animation: fadeIn 0.5s ease-in-out;
}

.inactive-section {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bullet-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.bullets {
  width: 86%;
  position: relative;
  z-index: 2;
}

.bullet {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #1f2235;
  height: 40px;
  margin: 15px 0;
  padding: 0 8px;
  border-radius: 20px;
  width: 30px;
  transition: width 0.6s ease;
}

.bullet-label {
  font-size: 18px;
  white-space: nowrap;
  font-family: "Poppins SemiBold";
}

.bullet-logo {
  height: 16px;
  margin: 0 30px 0 0;
}

.bullet-icons {
  width: 34px;
  height: 100%;
  z-index: 1;
  background-color: #1f2235;
  position: absolute;
}

.selected-bullet {
  color: var(--white);
  width: 100%;
}

/* RESUME CONTAINER */
.resume-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 360px;
  width: 100%;
}

.resume-heading {
  display: flex;
  flex-direction: column;
}

.resume-main-heading {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.resume-main-heading span {
  color: var(--dark-orange);
  font-size: 18px;
  font-family: "Poppins SemiBold";
}

.experience-description {
  max-width: 100%;
}

.heading-bullet {
  position: absolute;
  left: -30px;
  height: 15px;
  width: 15px;
  top: 5px;
  border-radius: 50%;
  background-color: #ff5823;
}

.resume-sub-heading {
  font-size: 14px;
  font-family: "Poppins SemiBold";
  color: var(--black);
  margin: 10px 0 0 0;
}

.resume-heading-description {
  font-size: 14px;
  text-align: justify;
}

.heading-date {
  background-color: #ff5823;
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 14px;
  color: var(--white);
}

.skill-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.work-experience-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 400px;
}

.work-experience-container::-webkit-scrollbar {
  width: 8px;
}

.work-experience-container::-webkit-scrollbar-thumb {
  background-color: #ff5823;
  border-radius: 4px;
}

.programming-skills-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto; 
  max-height: 400px; 
}

.programming-skills-container::-webkit-scrollbar {
  width: 8px;
}

.programming-skills-container::-webkit-scrollbar-thumb {
  background-color: #ff5823; 
  border-radius: 4px;
}

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto; 
  max-height: 400px;
}

.projects-container::-webkit-scrollbar {
  width: 8px;
}

.projects-container::-webkit-scrollbar-thumb {
  background-color: #ff5823;
  border-radius: 4px;
}

.skill-percentage {
  position: relative;
  height: 15px;
  width: 70%;
  border-radius: 14px;
  background-color: var(--dark-orange);
}

.skill-percentage .active-percentage-bar {
  height: 15px;
  transition: 0.8s ease;
  position: absolute;
  border-radius: 14px;
  background-color: #1f2235;
}

.skill-parent span {
  font-family: "Poppins Bold";
  font-size: 16px;
  color: #ff5823;
}

.skills-category {
  margin-bottom: 20px;
  width: 100%;
}

.skills-category h3 {
  font-size: 18px;
  font-weight: bold;
  color: #ff5823;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .resume-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 15px;
  }

  .resume-bullet-details {
    width: 100%;
    padding: 0;
    flex-grow: 1;
    min-width: 100%;
  }

  .programming-skills-container {
    width: 100%; 
    max-height: 400px; 
    overflow-y: scroll;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .skills-category {
    width: 100%;
    margin-bottom: 20px;
  }

  .skill-parent {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
  }

  .skill-percentage {
    height: 12px;
    width: 60%;
  }

  .resume-screen-container {
    width: 100%; 
    padding: 0 15px; 
    min-width: 100%; 
  }

  .resume-screen-container a img {
    max-width: 100%;
    height: auto;
  }

  .bullet-container {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow-y: auto;
    max-height: 400px;
  }

  .projects-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .projects-container::-webkit-scrollbar-thumb {
    background-color: #ff5823;
    border-radius: 4px;
  }

  .project-github-link {
    display: block;
    margin-top: 8px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .project-github-link:hover {
    text-decoration: underline;
  }

  .bullets {
    width: 100%;
  }

  .resume-main-heading span {
    font-size: 16px;
  }

  .resume-sub-heading {
    font-size: 14px;
  }

  .resume-description-text {
    font-size: 13px;
  }
}

/* IPAD RESPONSIVENESS */
@media only screen and (max-width: 1023px) {
  .resume-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bullets {
    width: 90%;
  }

  .resume-card {
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    align-items: center;
    width: 100%;
  }

  .resume-bullet-details {
    height: auto;
    padding: 0;
    width: 100%;
    flex-grow: 1;
    min-width: 100%;
  }

  .resume-bullets {
    margin: 30px 0;
    width: 100%;
  }

  .selected-bullet {
    width: 100%;
  }
}

/* Desktop Adjustments */
@media only screen and (min-width: 1200px) {
  .resume-card {
    width: 1000px;
    padding: 0 20px;
  }

  .resume-bullet-details {
    padding: 0 0 0 70px;
  }
}
